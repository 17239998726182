import React, { useState, useEffect } from "react";
import styles from "./Carousel.module.css";
import Vikas from "../../assets/Vikas.png";
import Pranav from "../../assets/Pranav.png";
import Aditya from "../../assets/Aditya.png";
import Siddhartha from "../../assets/Siddhartha.png";
import Vinay from "../../assets/Vinay.png";


const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [autoScrollInterval, setAutoScrollInterval] = useState(null);

  const testimonials = [
    {
      quote:
        "I Won a Huge amount Of Cash Prize from Tricket, & from my winning I pay my own debt.",
      name: "Vikas Kumar",
      image: Vikas,
      won: "3 Lakh",
    },
    {
      quote:
        "As an avid Cricket watcher and a fan,Tricket is a very different concept. I love the idea and how it is different from other apps.",
      name: "Pranav Menon",
      image: Pranav,
      won: " 2 Lakh",
    },
    {
      quote: "I absolutely enjoy this app. Playing real time and being able to test my cricket knowledge while winning cash prizes, is an amazing concept.",
      name: "Aditya Pramod",
      image: Aditya,
      won: " 1 Lakh",
    },
    {
      quote: "5 stars . This is totally a new and unique concept. Enjoying it so much.",
      name: "Siddhartha KS",
      image: Siddhartha,
      won: " 2.4 Lakh",
    },
    {
      quote: "Best Fantasy app in India. Love their Real-Time concept,that's unique! I Purchase a motor-cyle from the tricket winnings.",
      name: "Vinay Kumar",
      image: Vinay,
      won: " 3 Lakh",
    },
  ];

  const handleButtonClick = (index) => {
    setActiveIndex(index);
    clearInterval(autoScrollInterval); // Clear auto-scroll when manually selecting slide
  };

  useEffect(() => {
    // Auto-scroll interval
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) =>
        prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Adjust the interval duration (in milliseconds) as needed

    setAutoScrollInterval(interval);

    return () => {
      clearInterval(interval); // Clear the interval on component unmount
    };
  }, []);

  return (
    <main className={styles.main}>
      <ul
        className={styles.slider}
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {testimonials.map((testimonial, index) => (
          <li key={index} className={styles.item}>
            <div className={styles.testimonial}>
              <div className={styles.upperDiv}>
                <img
                  className={styles.image}
                  src={testimonial.image}
                  alt={testimonial.name}
                />
                <p>
                  <span>Won: </span> &#8377; {testimonial.won}
                </p>
              </div>
              <div className={styles.lowerDiv}>
                <p>{testimonial.name}</p>
                <p>{testimonial.quote}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <nav>
        {testimonials.map((_, index) => (
          <button
            key={index}
            className={`${styles.btn} ${
              activeIndex === index ? styles.expand : ""
            }`}
            data-index={index}
            onClick={() => handleButtonClick(index)}
          ></button>
        ))}
      </nav>
    </main>
  );
};

export default Carousel;
