import React, { useState, useEffect } from "react";
import styles from "./Navbar.module.css";
import logoWhite from "../../assets/LogoWhite.png";
import logoPurple from "../../assets/LogoPurple.png";
import arrow from "../../assets/arrow_white.png";
import arrowPurple from "../../assets/arrow.png";
import { useNavigate } from "react-router-dom";
import Modal from "../../Components/Modals/Modal";
//import DownloadMetaPixel from "../../pages/downloadCTA";
import { debounce } from "lodash";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isAboutPage, setIsAboutPage] = useState(false);

  useEffect(() => {
    if (
      window.location.pathname === "/about-us" ||
      window.location.pathname === "/faqs" ||
      window.location.pathname === "/privacy-policy" ||
      window.location.pathname === "/terms-conditions" ||
      window.location.pathname === "/return-policies" ||
      window.location.pathname === "/responsible-game-play"
    ) {
      setIsAboutPage(true);
    } else {
      setIsAboutPage(false);
    }
  }, []);

  const navigate = useNavigate();

  const scrollToHowToPlay = () => {
    // Programmatically navigate to the home page (or any desired page)
    navigate("/"); // Assuming '/' is your home page route
    // Scroll to the #hero section
    const heroSection = document.getElementById("howToPlay");
    if (heroSection) {
      heroSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 20) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const isMacOS = navigator.platform.toUpperCase().includes("MAC");
  const isAndroid = /Android/.test(navigator.userAgent);
  const isiPhone = /iPhone/.test(navigator.userAgent);
  const isWindows = /Win/.test(navigator.platform);
  const isMacOSOriPhone = isMacOS || isiPhone;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    handleLeadClick()
  };
  const handleLeadClick = debounce(() => {
    console.log("handleLeadClick called in navbar")
    if (window.fbq) {
      window.fbq('track', 'Lead', {
        content_name: 'Lead Conversion'
      });
      console.log('Lead event fired');
    } else {
      console.error('Facebook Pixel not initialized');
    }
  },500);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {/* Hamburger Menu Button */}

      {/* Navbar */}

      <nav
        className={`${styles.navbar} ${scrolled ? styles.scrolled : ""} ${isOpen ? styles.open : ""
          } ${isAboutPage ? styles.aboutPage : ""}`}
        id="Navbar"
      >
        <a href="/" className={styles.navLogo}>
          {scrolled || isAboutPage ? (
            <img src={logoPurple} alt="Logo" />
          ) : (
            <img src={logoWhite} alt="Logo" />
          )}
        </a>
        <div className={styles.navLinks}>
          <a
            href="#howToPlay"
            className={styles.link}
            onClick={scrollToHowToPlay}
          >
            How to Play
          </a>
          <a href="/about-us" className={styles.link}>
            About Us
          </a>
          <a href="/" className={styles.link}>
            Blogs
          </a>
        </div>
        <div className={styles.navButtons}>
          <button type="button" className={styles.navButton}>
            Contact Us
          </button>
          <button type="button" className={styles.navButton}>
            {isMacOSOriPhone ? (
              <a
                href="https://apps.apple.com/in/app/tricket-analyse-win/id1667156965"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }}
                onClick={handleLeadClick}
              >
                Play Now
              </a>
            ) : (
              <a
                href="/Tricket.apk"
                download
                onClick={openModal}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }}
              >
                Play Now
              </a>
            )}
            {/* {isMacOS && <a
              href="https://apps.apple.com/in/app/tricket-analyse-win/id1667156965" target="_blank"
              style={{
                textDecoration: "none",
                color: "inherit",
                cursor: "pointer",
              }}
            >
              Play Now
            </a>}
            
            {isiPhone && <a
              href="https://apps.apple.com/in/app/tricket-analyse-win/id1667156965" target="_blank"
              style={{
                textDecoration: "none",
                color: "inherit",
                cursor: "pointer",
              }}
            >
              Play Now
            </a>}

            {isWindows && <a
              href="/Tricket.apk" download  onClick={openModal}
              style={{
                textDecoration: "none",
                color: "inherit",
                cursor: "pointer",
              }}
            >
              Play Now
            </a>}
            {isAndroid && <a
              href="/Tricket.apk" download  onClick={openModal}
              style={{
                textDecoration: "none",
                color: "inherit",
                cursor: "pointer",
              }}
            >
              Play Now
            </a>} */}


            {scrolled || isAboutPage ? (
              <img src={arrow} alt="arrow" />
            ) : (
              <img src={arrowPurple} alt="arrow" />
            )}
          </button>
        </div>
        <div className={styles.hamburger} onClick={toggleMenu}>
          <div className={styles.bar}></div>
          <div className={styles.bar}></div>
          <div className={styles.bar}></div>
        </div>
      </nav>

      {/* Slider Menu */}
      <div className={`${styles.slider} ${isOpen ? styles.slideIn : ""}`}>
        <div className={styles.sliderLogo}>
          <img src={logoPurple} alt="Logo" />
        </div>

        <div className={styles.sliderContent}>
          <a href="#howToPlay"
            className={styles.sliderLink}
            onClick={scrollToHowToPlay} >
            How to Play ?
          </a>
          <a href="/about-us" className={styles.sliderLink}>
            About Us
          </a>
          <a href="/" className={styles.sliderLink}>
            Blogs
          </a>
          <button type="button" className={styles.sliderButton}>
            Contact Us
          </button>
          <button type="button" className={styles.sliderButton}>
            {isMacOSOriPhone ? (
              <a
                href="https://apps.apple.com/in/app/tricket-analyse-win/id1667156965"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }}
                onClick={handleLeadClick}
              >
                Play Now
              </a>
            ) : (
              <a
                href="/Tricket.apk"
                download
                onClick={openModal}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }}
              >
                Play Now
              </a>
            )}
            <img src={arrow} alt="arrow" />
          </button>
        </div>
        <div className={styles.closeBtnContainer} onClick={toggleMenu}>
          <div className={styles.closeBtn}>
            <div className={styles.cross}>&times;</div>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default Navbar;
