import React from "react";
import styles from "./home.module.css";
import Hero from "./Hero/hero";
import Testimonials from "./Testimonials/testimonials";
import HowToPlay from "./HowToPlay/HowToPlay";
import WhyTricket from "./WhyTricket/WhyTricket";
import PageLoadPixel from "../pageload";


const Home = () => (
  <div className={styles.home}>
    <PageLoadPixel />
    <Hero />
    <HowToPlay />
    <WhyTricket />
    <Testimonials />
  </div>
);

export default Home;
