import React from "react";
import classes from "../Privacy/Privacy.module.css";
const Refund = () => {
  return (
    <>
      <div className={classes.container}>
        <h1>Refund And Policies</h1>
    
      </div>
      <div className={classes.container2}>
        <div>
        <p>Thank you for your participation in Tricket. We hope you are happy with our gameplay. If you have faced any monetary transaction-related issues or other issues then drop a mail to us on team@tricket.in</p>



<p><strong>Return, Refund and Cancellation<br /></strong><br />No refund will be accepted if you have participated in the gameplay and it has not fallen under our Exceptions. All exceptional refunds to the payment source will be done within 5-7 working days.<br />Exceptions<br />a) If a Room/Contest does not reach its total capacity (minimum entry) and remains unfilled, the joining amount or the Entry Fee will be refunded to the customer’s Tricket wallet.<br />b) If a minimum of 2 Overs of a Spell have not been bowled, all the Rooms/Contests associated with that Spell will be cancelled, and the joining amount or the Entry Fee will be refunded to the customer’s Tricket wallet. This situation may arise due to factors such as a shortened match caused by the DLS method, an early finish of the inning due to the batting team getting all out early, or the batting team winning the game early.<br />c) Deposited/Credited amount cannot be refunded.<br />You can only withdraw the winning amount which you win in the Tricket Contests.<br />d) The Minimum Withdrawal amount is ₹50 and the Maximum withdrawal amount is ₹10,000 daily.<br />e) In the event of any dispute between the customer and Tricket management, the Tricket management team will make the final decision.<br />Questions<br />If you have any questions or queries then please write to us on team@tricket.in</p>
						
        </div>
      </div>
    </>
  );
};

export default Refund;
