import React from "react";
import styles from "./testimonials.module.css";
import Carousel from "../../../Components/Carousel/Carousel";


const Testimonials = () => {
  return (
    <section className={styles.testimonials}>
      <p className={styles.testHeader}>See what Winners have to say?</p>
      <Carousel />
    </section>
  );
};

export default Testimonials;
