import React, { useState, useEffect } from "react";
import styles from "./Footer.module.css";
// import Logo from "../../assets/LogoWhite.png";
import Logo from "../../assets/logoWhiteblueball.png";
import MakeInIndia from "../../assets/MakeInIndia.png";
import arrow from "../../assets/arrow.png";
import AndroidImage from "../../assets/android.png";
import Modal from "../../Components/Modals/Modal";
import { useNavigate } from "react-router-dom";
//import DownloadMetaPixel from "../../pages/downloadCTA";
import { debounce } from "lodash";

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMacOS = navigator.platform.toUpperCase().includes("MAC");
  const isAndroid = /Android/.test(navigator.userAgent);
  const isiPhone = /iPhone/.test(navigator.userAgent);
  const isWindows = /Win/.test(navigator.platform);
  const isMacOSOriPhone = isMacOS || isiPhone;
  const [showDiv, setShowDiv] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    handleLeadClick()
  };

  const handleLeadClick =  debounce(() => {
    console.log("handleLeadClick called in footer")
    if (window.fbq) {
      window.fbq('track', 'Lead', {
        content_name: 'Lead Conversion'
      });
      console.log('Lead event fired');
    } else {
      console.error('Facebook Pixel not initialized');
    }
  },500);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const navigate = useNavigate();
  const scrollToHero = () => {
    // Programmatically navigate to the home page (or any desired page)
    navigate("/"); // Assuming '/' is your home page route
    // Scroll to the #hero section
    const heroSection = document.getElementById("hero");
    if (heroSection) {
      heroSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToHowToPlay = () => {
    // Programmatically navigate to the home page (or any desired page)
    navigate("/"); // Assuming '/' is your home page route
    // Scroll to the #hero section
    const heroSection = document.getElementById("howToPlay");
    if (heroSection) {
      heroSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setShowDiv(true);
      } else {
        setShowDiv(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className={styles.footerContainer}>
        <div className={styles.ftrContent}>
          <div className={styles.topContainer}>
            <div className={styles.ftrTopContent}>
              <p>Play The Game, Not Just The Sport.</p>
              <span>
                We believe that cricket and fantasy sports fans should be able to
                experience the entire game, not just the players.
              </span>
              <button
                type="button"
                className={styles.ftrButton}
                onClick={openModal}
              >
                <a
                  href="/Tricket.apk"
                  download
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    cursor: "pointer",
                  }}
                >
                  Download Now
                  <img src={arrow} alt="arrow" />
                </a>
              </button>
            </div>
          </div>
          <div className={styles.ftrMidContent}>
            <div className={styles.leftContent}>
              <img src={Logo} alt="Logo" />
              <p> They Love
                <br className={styles.onMobile} />

                The Players,</p>
              <span> We Love
                <br className={styles.onMobile} />

                The Game.</span>
            </div>
            <div className={styles.rightContent}>
              <img src={MakeInIndia} alt="MakeInIndia" />
            </div>
          </div>
          <div className={styles.ftrLinks}>
            <div className={styles.leftContent}>
              <div className={styles.linkContainers}>
                <h3 className={styles.linkHeadings}>Quick Links</h3>
                <a href="#hero" className={styles.links} onClick={scrollToHero}>
                  Home
                </a>
                <a
                  href="#howToPlay"
                  className={styles.links}
                  onClick={scrollToHowToPlay}
                >
                  How To Play?
                </a>
                <a href="/about-us" className={styles.links}>
                  About Us
                </a>
                <a href="/" className={styles.links}>
                  Blogs
                </a>
              </div>
              <div className={styles.linkContainers}>
                <h3 className={styles.linkHeadings}>Support</h3>
                <a href="/faqs" className={styles.links}>
                  FAQ&apos;s
                </a>
                <a href="/privacy-policy" className={styles.links}>
                  Privacy Policy
                </a>
                <a href="/return-policies" className={styles.links}>
                  Refund and Return Policy
                </a>
                <a href="/terms-conditions" className={styles.links}>
                  Terms and Conditions
                </a>
              </div>
            </div>
            <div className={styles.rightContent}>
              <div className={styles.linkContainers}>
                <h3 className={styles.linkHeadings}>Contact</h3>
                <p className={styles.links}>team@cricket.in</p>
                <p className={styles.links}>
                  7th Floor, Monibhandar Building,
                  <br /> Webel Bhavan Premises, Ring Rd, GP
                  <br /> Block, Sector V, Bidhannagar,
                  <br /> Kolkata 700091
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ftrEnd}>
          <p>© all rights reserved to Helpen.In Ent. Pvt. Ltd. </p>
          <span>
            {" "}
            &nbsp; This game may be habit-forming or financially risky. Play
            responsibly✌️
          </span>
        </div>
        <div className="">
          {/* Your other content */}
          {/* <button className={styles.stickyButton}>Click me</button> */}
          {showDiv &&
            <div className={styles.stickyButton}>
              <div className={styles.discount}>GET &#8377; 50 FREE</div>

              {isMacOSOriPhone ? (
                <a
                  href="https://apps.apple.com/in/app/tricket-analyse-win/id1667156965"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    cursor: "pointer",
                  }}
                  onClick={handleLeadClick}
                >
                  {" "}
                  <button className={styles.stickyDownload}>Download &#8594;</button>
                </a>
              ) : (
                <a
                  href="/Tricket.apk"
                  download
                  onClick={openModal}
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    cursor: "pointer",
                  }}
                >
                  <button className={styles.stickyDownload} onClick={openModal}>
                    Download For
                    <img
                      src={AndroidImage}
                      alt="Android"
                      className={styles.heroBtnDownImg}
                    />
                  </button>
                </a>
              )}
              {/* <a
              href="/Tricket.apk"
              download
              style={{
                textDecoration: "none",
                color: "inherit",
                cursor: "pointer",
              }}
            >
              Download For
              <img
                src={AndroidImage}
                alt="Android"
                className={styles.heroBtnDownImg}
              />
            </a> */}
              {/* </button> */}
            </div>}
        </div>
        <Modal isOpen={isModalOpen} onClose={closeModal} />
      </div>
    </>

  );
};

export default Footer;
