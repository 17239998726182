import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import Home from "./pages/Home/home";
import About from "./pages/AboutUs/aboutUs";
import HowToPlay from "./pages/FAQ/faq";
import Privacy from "./pages/Privacy/privacy";
import Terms from "./pages/Terms/terms";
import Responsible from "./pages/ResponsibleGamePlay/responsibleGamePlay";
import Refund from "./pages/Refund/refund";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/faq" element={<FAQ />} /> */}
          <Route path="/about-us" element={<About />} />
          <Route path="/faqs" element={<HowToPlay />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms-conditions" element={<Terms />} />
          <Route path="/responsible-game-play" element={<Responsible />} />
          <Route path="/return-policies" element={<Refund />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
