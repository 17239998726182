// Modal.js
import React from "react";
import styles from "./Modal.module.css";
import Step1 from "../../assets/Step1.png";
import Step2 from "../../assets/Step2.png";
import Step3 from "../../assets/Step3.png";
import Step4 from "../../assets/Step4.png";

const Modal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <nav className={styles.modalHeader}>
          <button className={styles.closeButton} onClick={onClose}>
            &times;
          </button>

          <div className={styles.modalTitle}>Tricket</div>
        </nav>
        <div className={styles.modalBody}>
          <div className={styles.modalItem}>
            <img src={Step1} alt="Step1" />
          </div>
          <div className={styles.modalItem}>
            <img src={Step2} alt="Step2" />
          </div>
          <div className={styles.modalItem}>
            <img src={Step3} alt="Step3" />
          </div>
          <div className={styles.modalItem}>
            <img src={Step4} alt="Step4" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
