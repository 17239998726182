import React, { useEffect } from 'react';

const PageLoadPixel = () => {
    console.log("PageLoadPixel called");

    useEffect(() => {
        if (!window.fbq) {
            (function (f, b, e, v, n, t, s) {
                if (f.fbq) return;
                n = f.fbq = function () {
                    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
                };
                if (!f._fbq) f._fbq = n;
                n.push = n;
                n.loaded = !0;
                n.version = "2.0";
                n.queue = [];
                t = b.createElement(e);
                t.async = !0;
                t.src = v;
                s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s);
            })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");

            window.fbq("init", "1121861205709964");
        }

        // Track PageView only if fbq exists
        if (window.fbq) {
            window.fbq("track", "PageView");
        }

        // Append the no-script pixel only once
        const noscriptId = "fb-ns-pixel";
        if (!document.getElementById(noscriptId)) {
            const noscript = document.createElement("noscript");
            noscript.id = noscriptId;
            noscript.innerHTML = `
              <img height="1" width="1" style="display:none"
              src="https://www.facebook.com/tr?id=1121861205709964&ev=PageView&noscript=1"/>
            `;
            document.body.appendChild(noscript);
        }

        return () => {
            const scriptElement = document.querySelector('script[src="https://connect.facebook.net/en_US/fbevents.js"]');
            if (scriptElement) document.head.removeChild(scriptElement);
        };
    }, []);

    return null;
};

export default PageLoadPixel;
