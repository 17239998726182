import { useState, useEffect } from "react";
import classes from "./HowToPlay.module.css";
import option1 from "../../../assets/option1.png";
import option2 from "../../../assets/option2.png";
import option3 from "../../../assets/option3.png";
import option4 from "../../../assets/option4.png";
import option5 from "../../../assets/option5.png";
import arrow from "../../../assets/arrow.png";

const options = [
  {
    number: 1,
    title: "Choose any ongoing or upcoming matches",
    image: option5,
  },
  {
    number: 2,
    title: "Choose a spell of an innings and make your predictions",
    image: option4,
  },
  {
    number: 3,
    title:
      "Join Rooms with your predictions to compete with other participants",
    image: option3,
  },
  {
    number: 4,
    title:
      "Earn points corresponding to your predictions once the spell starts",
    image: option2,
  },
  {
    number: 5,
    title:
      "Top your room leaderboards to win cash prize and withdraw directly to your bank account",
    image: option1,
  },
];

const HowToPlay = () => {
  const [selectedOption, setSelectedOption] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedOption((prevOption) =>
        prevOption === options.length ? 1 : prevOption + 1
      );
    }, 5000); // Change option every 5 seconds

    return () => clearInterval(interval);
  }, []);

  const handleButtonClick = (index) => {
    setSelectedOption(index);
  };

  return (
    <div>
       <div id="howToPlay" style={{}}></div>
    <div className={classes.container}>
      <div className={classes.left__div}>
        <h2 >How To Play?</h2>
        <div className={classes.horizontal_box}>
        {options.map((option) => (
          <div
            key={option.number}
            onClick={() => setSelectedOption(option.number)}
            className={`${classes.option} ${
              selectedOption === option.number ? classes.active : ""
            }`}
          >
            <div className={classes.option_content}>
              <div className={classes.option_content__left}>
                <div className={classes.number}>
                  <p>{option.number}</p>
                </div>
                <h5>{option.title}</h5>
              </div>
              {selectedOption === option.number && (
                <img src={arrow} alt="arrow" />
              )}
            </div>
          </div>
        ))}
        </div>
      </div>
      <div className={classes.right__div}>
        <nav>
          {options.map((_, index) => (
            <button
              key={index + 1}
              className={`${classes.btn} ${
                selectedOption === index + 1 ? classes.expand : ""
              }`}
              data-index={index + 1}
              onClick={() => handleButtonClick(index + 1)}
            ></button>
          ))}
        </nav>
        {options.map(
          (option) =>
            selectedOption === option.number && (
              <div key={option.number} className={classes.card}>
                <img src={option.image} alt={`Option ${option.number}`} />
              </div>
            )
        )}
      </div>
    </div>
    </div>
  );
};

export default HowToPlay;
